import React from 'react';

import type { ImageSetProps } from './ImageSet.types';

export const ImageSet = ({
  src,
  srcAvif,
  srcWebp,
  className,
  lazy,
  alt,
  height,
  width,
}: ImageSetProps) => (
  <picture>
    {srcWebp && <source srcSet={srcWebp} type='image/webp' />}
    {srcAvif && <source srcSet={srcAvif} type='image/avif' />}
    <img
      className={className}
      loading={lazy ? 'lazy' : 'eager'}
      src={src}
      alt={alt}
      width={width}
      height={height}
    />
  </picture>
);
